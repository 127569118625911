const certifications = () => {
  const certContent = document.querySelector(".cert__content");
  const certBtns = document.querySelectorAll(".cert__btn");
  const certLists = document.querySelectorAll(".certifications-list");
  const certContentLinks = document.querySelectorAll(".cert__content__link");
  const certModals = document.querySelectorAll(".cert__modal");

  if (certContent && certBtns.length > 0 && certLists.length > 0) {

    /**
     * Tabs - Btn Open
     */

    certBtns.forEach((certBtn, i) => {
      certBtn.addEventListener("click", () => {
        certBtns.forEach((btn) => {
          btn.classList.remove("active");
        });
        certLists.forEach((list) => {
          list.classList.remove("active");
        });
        certBtn.classList.add("active");
        certContent.style.height = `${certLists[i].clientHeight}px`;
        certLists[i].classList.add("active");

      });
    });

    /**
     * Modals - Btn Open
     */

    if (certContentLinks.length > 0 && certModals.length > 0) {
      certContentLinks.forEach((certContentLink) => {
        const dataModal = certContentLink.getAttribute("data-modal");
        if (dataModal) {
          certContentLink.addEventListener("click", (e) => {
            e.preventDefault();
            certModals.forEach((certModal) => {
              if (certModal.id === dataModal) {
                certModal.classList.add("active");
              } else {
                certModal.classList.remove("active");
              }
            });
          });
        }
      });

      /**
       * Modals - Btn Close
       */

      certModals.forEach((certModal) => {
        const btnClose = certModal.querySelector(".cert__modal__close");
        if (btnClose) {
          btnClose.addEventListener("click", () => {
            certModal.classList.remove("active");
          });
        }
      });
    }
  }
};

export default certifications;
