/**
 * JQuery
 */

import $ from "jquery";

window.jQuery = $;
window.$ = $;

/**
 * Bootstrap
 */

import {
  Alert, // Dismissible alerts
  Button, // Toggle button states
  Carousel, // Slideshow component
  Collapse, // Accordion-like collapsing elements
  Dropdown, // Dropdown menus
  Modal, // Popup modals
  Offcanvas, // Offcanvas sidebars
  Popover, // Pop-up content on hover/click (requires @popperjs/core)
  ScrollSpy, // Auto-updating navigation based on scroll position
  Tab, // Tabs and pills navigation
  Toast, // Small pop-up notifications
  Tooltip, // Tooltips for elements (requires @popperjs/core)
} from "bootstrap";

/**
 * Fancybox
 */

import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";

/**
 * Swiper
 */

// import Swiper from "swiper/bundle";
// import "swiper/css/bundle";
// window.Swiper = Swiper;

import Swiper, { Autoplay, Navigation, Pagination } from "swiper";
Swiper.use([Autoplay, Navigation, Pagination]);

/**
 * DOC READY
 */

$(() => {
  /**************************************************************************************
            REMOVE BODY ONLOAD LAYER
            Remove the body layer after bootstrap's plugins ( menu, etc...) are loaded
        **************************************************************************************/
  $("body").removeClass("body-loading");
  $(".body-loader").fadeOut(400);

  /**************************************************************************************
            CUSTOM THEME CODE
         **************************************************************************************/

  /**
   * Fixed Navbar
   */
  const header = $("#header");
  const wrapper = $("#main-wrapper");

  $(window).on("scroll", function () {
    // fixed navbar
    if ($(window).scrollTop() > 0) {
      header.addClass("scrolled");
      wrapper.addClass("scrolled");
    } else {
      header.removeClass("scrolled");
      wrapper.removeClass("scrolled");
    }
  });

  // Hmburger active
  $(".navbar-toggler").on("click", function () {
    $(this).toggleClass("open");
  });

  /**
   * Hero slider
   */
  const heroSlides = $(".hero-slider .swiper-slide").length;
  const slider = new Swiper(".hero-slider", {
    // Optional parameters
    autoplay:
      heroSlides > 1
        ? {
            delay: 3000,
          }
        : false,
    speed: 700,
    loop: heroSlides > 1 ? true : false,
    allowTouchMove: heroSlides > 1 ? true : false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  /**
   * Sections carousel with gallery
   * init one for every section
   */
  // List of multiple carousel instances
  const carouselList = [];

  // Loop on the sections
  $(".content-section.with-slider").each(function (i) {
    // Actual section number
    const sectionNumb = i + 1;

    carouselList.push(
      new Swiper(`.content-section-${sectionNumb} .sections-carousel`, {
        // Optional parameters
        autoplay: $(`.content-section-${sectionNumb}`).hasClass("with-gallery")
          ? false
          : {
              delay: 3000,
            },
        speed: 700,
        centeredSlides: true,
        breakpoints: {
          //>= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          // >= 576px
          576: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          // 768px
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        },
        // Navigation arrows
        navigation: {
          nextEl: `.content-section-${sectionNumb} .swiper-button-next`,
          prevEl: `.content-section-${sectionNumb} .swiper-button-prev`,
        },
        // Pagination
        pagination: {
          el: `.content-section-${sectionNumb} .slider-pagination`,
          type: "fraction",
          formatFractionCurrent: function (number) {
            return number < 10 ? "0" + number : number;
          },
          formatFractionTotal: function (number) {
            return number < 10 ? "0" + number : number;
          },
        },
      })
    );

    // Cusatom arrow navigation outside the carousel container
    // Note: arrow on the container + arrow outside does not work togheter
    // Prev
    $(`.content-section-${sectionNumb} .swiper-button-prev-nav`).on("click", function () {
      carouselList[sectionNumb - 1].slidePrev();
    });
    // Next
    $(`.content-section-${sectionNumb} .swiper-button-next-nav`).on("click", function () {
      carouselList[sectionNumb - 1].slideNext();
    });

    // Dynamic slide text outside the carousel (updates on slide change)
    // Set on startup
    let activeSlideText = $(`.content-section-${sectionNumb} .slide-text1`);
    if (activeSlideText.length > 0) {
      $(`.content-section-${sectionNumb} .text`).html($(`.content-section-${sectionNumb} .slide-text1`).html());
    }

    carouselList[sectionNumb - 1].on("slideChange", function () {
      // Check disabled arrow navigation outisde the carousel container
      if (carouselList[sectionNumb - 1].isBeginning) {
        $(`.content-section-${sectionNumb} .swiper-button-prev-nav`).addClass("swiper-button-disabled");
        $(`.content-section-${sectionNumb} .swiper-button-next-nav`).removeClass("swiper-button-disabled");
      } else if (carouselList[sectionNumb - 1].isEnd) {
        $(`.content-section-${sectionNumb} .swiper-button-next-nav`).addClass("swiper-button-disabled");
        $(`.content-section-${sectionNumb} .swiper-button-prev-nav`).removeClass("swiper-button-disabled");
      } else {
        $(`.content-section-${sectionNumb} .swiper-button-prev-nav`).removeClass("swiper-button-disabled");
        $(`.content-section-${sectionNumb} .swiper-button-next-nav`).removeClass("swiper-button-disabled");
      }

      // Update slide text outside the carousel container
      if (activeSlideText.length > 0) {
        $(`.content-section-${sectionNumb} .separator`).fadeOut(500);
        $(`.content-section-${sectionNumb} .text`).fadeOut(500, function () {
          $(`.content-section-${sectionNumb} .text`).html($(`.content-section-${sectionNumb} .slide-text${carouselList[sectionNumb - 1].activeIndex + 1}`).html());
          $(`.content-section-${sectionNumb} .separator`).fadeIn();
          $(`.content-section-${sectionNumb} .text`).fadeIn();
        });
      }
    });
  });

  // CAROUSEL GALLERY
  Fancybox.bind("[data-fancybox]", {
    // Modal carousel autoplay
    slideshow: {
      infinite: false,
      autoStart: false,
      delay: 3000,
    },
    Toolbar: {
      display: [{ id: "counter", position: "center" }, "zoom", "slideshow", "close"],
    },
    Carousel: {
      Navigation: {
        prevTpl: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 27.576 32"><g transform="translate(27.576 32) rotate(180)"><g transform="translate(0)"><path d="M19.032,0-8.544,16,19.032,32Z" transform="translate(8.544)" /></g></g></svg>',
        nextTpl: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 27.576 32"><g transform="translate(27.576 32) rotate(180)"><g transform="translate(0)"><path d="M19.032,0-8.544,16,19.032,32Z" transform="translate(8.544)" /></g></g></svg>',
      },
    },
  });

  /**
   * Dark bar mobile toggle
   */
  const darkBar = $(".link-bar-dark");
  darkBar.find(".title").on("click", function () {
    $(this).find(".hamburger").toggleClass("is-active");
    darkBar.find(".links").slideToggle();
  });

  /**
   * Animate counter numbers
   */
  if ($(".home-intro-counter").length > 0) {
    // Init
    const countersTop = $(".home-intro-counter").offset().top + 200;
    const numbers = $(".counter-item .text-one span");
    numbers.each(function () {
      $(this).attr("data-count", $(this).text());
      $(this).text(0);
    });

    let interval = 1000;
    let reaced = false;
    $(window).on("scroll", function () {
      let viewportTop = $(window).scrollTop();
      let viewportBottom = viewportTop + $(window).height();
      if (viewportBottom > countersTop && !reaced) {
        reaced = true;
        $(".counter-item .text-one span").each(function () {
          let count = 0;
          let endValue = parseInt($(this).attr("data-count"));
          let duration = Math.floor(interval / endValue);
          let counter = setInterval(() => {
            count++;
            $(this).text(count);
            if (count == endValue) {
              clearInterval(counter);
            }
          }, duration);
        });
      }
    });
  }
}); // End Doc Ready

import certifications from "./certifications";
certifications();
